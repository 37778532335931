import { useState, useEffect } from "react";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import styled from "styled-components";
import RadioIO from './RadioIO';

const NavStyle = styled(NavLink)`
  color: green;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  margin: 5px;
  outline: invert;
  &:link {
    transition : 0.5s;
    text-decoration: none;
  }
  &:hover {
    color: green;
  }
  &.active {
    color: aqua;
    position: relative;
    top: 2px;
  }
`

export default function Record() {
  const [form, setForm] = useState({
    date: "",
    category: "",
    detail: "",
    department: "",
    consignee: "",
    name: "",
    amount: "",
    note: "",
  });
  const [isNew, setIsNew] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const [radioValue, setRadioValue] = useState('income');
  const [selectedCate, setSelectedCate] = useState('Other');
  const [selectedDep, setSelectedDep] = useState('');
  const [selectedDetail, setSelectedDetail] = useState('');
  const [selectedName, setSelectedName] = useState('');

  
    // 카테고리별 디테일 옵션 정의
    const detailOptions = {
      Regular: ['Select Detail','Sunday', 'Tithe', 'Sunday School', 'Other'],
      Special: ['Select Detail','Thanksgiving', 'Building', 'Mission', 'Other'],
      Seasonal: ['Select Detail','Easter', 'Thankgiving', 'Christmas', 'Other'],
      Other: []
    };

  const handleCate = (e) => {
    setSelectedCate(e.target.value);
    setSelectedDetail('');  // 디테일 선택 초기화
    updateForm({category: e.target.value});
  };

  const handleDep = (e) => {
    setSelectedDep(e.target.value);
    updateForm({department: e.target.value});
  };

    // 디테일 선택 업데이트 함수
    const handleDetailChange = (e) => {
      setSelectedDetail(e.target.value);
      updateForm({ detail: e.target.value });
    };

    const handleNameChange = (e) => {
      setSelectedName(e.target.value);
      updateForm({ name: e.target.value });
    };

  useEffect(() => {
    async function fetchData() {
      const id = params.id?.toString() || undefined;
      if(!id) return;
      setIsNew(false);
      const response = await fetch(
        `http://202.169.212.28:5050/record/${params.id.toString()}`
      );
      if (!response.ok) {
        const message = `An error has occurred: ${response.statusText}`;
        console.error(message);
        return;
      }
      const record = await response.json();
      if (!record) {
        console.warn(`Record with id ${id} not found`);
        navigate("/home");
        return;
      }
      setForm(record);
    }
    fetchData();
    return;
  }, [params.id, navigate]);

  // These methods will update the state properties.
  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  // This function will handle the submission.
  async function onSubmit(e) {
    e.preventDefault();
    const person = { ...form };
    try {
      let response;
      if (isNew) {
        // if we are adding a new record we will POST to /record.
        response = await fetch("http://202.169.212.28:5050/record", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(person),
        });
      } else {
        // if we are updating a record we will PATCH to /record/:id.
        response = await fetch(`http://202.169.212.28:5050/record/${params.id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(person),
        });
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('A problem occurred with your fetch operation: ', error);
    } finally {
      setForm({ date: "", category: "", detail: "", department: "", consignee: "", name: "", amount: 0, note: "", });
      navigate("/home");
    }
  }

  // This following section will display the form that takes the input from the user.
  return (
    <>
    <RadioIO radioValue={radioValue} setRadioValue={setRadioValue} />  
    { radioValue==='income' ? <form
        onSubmit={onSubmit}
      >
        <div >
          <div>
          <br />
            <h2>
              Add Income
            </h2>
          </div>
          <br />
      <br />
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8">
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="date" column sm="2">Date</Form.Label>
              <Col sm="4">
                <Form.Control
                  type="date"
                  name="date"
                  id="date"
                  aria-describedby="Date"
                  value={form.date}
                  onChange={(e) => updateForm({ date: e.target.value })}
                />
              </Col>

              <Form.Label htmlFor="category" column sm="2">Category</Form.Label>
              <Col sm="4">
                <Form.Select value={selectedCate} onChange={handleCate}>
                <option value="Regular">Regular</option>
                <option value="Special">Special</option>
                <option value="Seasonal">Seasonal</option>
                <option value="Other">Other</option>
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
            <Form.Label htmlFor="name" column sm="2">Name</Form.Label>
              <Col sm="4">

              <Form.Select value={selectedName} onChange={handleNameChange}>
                <option value="">Select Name</option>
                <option value="Kyoung Tae Kwon">Kyoung Tae Kwon</option>
                <option value="Sansuk Yoon">Sansuk Yoon</option>
                <option value="Changhee Han">Changhee Han</option>
                <option value="Kiwook Gwak">Kiwook Gwak</option>
                <option value="Han Moi Oh">Han Moi Oh</option>
                <option value="Sarang Yoo">Sarang Yoo</option>
                <option value="Hye Eun Jeong">Hye Eun Jeong</option>
                <option value="Shin Heoi Gu">Shin Heoi Gu</option>
                <option value="Seo Young Yang">Seo Young Yang</option>
                <option value="Seung hee Jung">Seung hee Jung</option>
                <option value="Juyoung Shin">Juyoung Shin</option>
                <option value="Kihyung Heo">Kihyung Heo</option>
                <option value="Jina Kim">Jina Kim</option>
                <option value="Ok Bae Kim">Ok Bae Kim</option>
                <option value="Other">Other</option>
                </Form.Select>


              </Col>
              <Form.Label htmlFor="detail" column sm="2">Detail</Form.Label>
              <Col sm="4">
                  {selectedCate === 'Other' ? (
                  <Form.Control
                  type="text"
                  name="detail"
                  id="detail"
                  value={form.detail}
                  onChange={(e) => updateForm({ detail: e.target.value })}
                  placeholder="Enter detail"
                  />
                  ) : (
                  <Form.Select value={selectedDetail} onChange={handleDetailChange}>
                  {detailOptions[selectedCate].map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </Form.Select>
              )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="amount" column sm="2">Amount</Form.Label>
              <Col sm="4">
                <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  name="amount"
                  id="amount"
                  aria-describedby="Amount"
                  placeholder="Amount"
                  value={form.amount}
                  onChange={(e) => updateForm({ amount: e.target.valueAsNumber })}
                />
                </InputGroup>
              </Col>
            </Form.Group>

            <FloatingLabel controlId="floatingTextarea2" label="Note">
            <Form.Control
              as="textarea"
              placeholder="Leave a note here"
              style={{ height: '100px' }}
              name="note"
              id="note"
              aria-describedby="Note"
              value={form.note}
              onChange={(e) => updateForm({ note: e.target.value })}
            />
          </FloatingLabel>
          </Form>
          </div>
        </div>
        <br/>
        <Button type="submit" variant="success">Save</Button>{' '}
        <NavStyle to="/home">Cancel</NavStyle>
      </form>
      :
      <form
        onSubmit={onSubmit}
      >
        <div >
          <div>
          <br />
            <h2>
              Add Expense
            </h2>
          </div>
          <br />
      <br />
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8">
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="date" column sm="2">Date</Form.Label>
              <Col sm="4">
                <Form.Control
                  type="date"
                  name="date"
                  id="date"
                  aria-describedby="Date"
                  value={form.date}
                  onChange={(e) => updateForm({ date: e.target.value })}
                />
              </Col>
              <Form.Label htmlFor="department" column sm="2">Department</Form.Label>
              <Col sm="4">
                <Form.Select value={selectedDep} onChange={handleDep} >
                <option value="">Select Department</option>
                <option value="Management">Management</option>
                <option value="Worship">Worship</option>
                <option value="Communion">Communion</option>
                <option value="Education">Education</option>
                <option value="Mission">Mission</option>
                <option value="Other">Other</option>
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
            <Form.Label htmlFor="consignee" column sm="2">Consignee</Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="consignee"
                  id="consignee"
                  aria-describedby="Consignee"
                  placeholder="Consignee"
                  value={form.consignee}
                  onChange={(e) => updateForm({ consignee: e.target.value })}
                />
              </Col>
            <Form.Label htmlFor="detail" column sm="2">Detail</Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="detail"
                  id="detail"
                  aria-describedby="Detail"
                  placeholder="Detail"
                  value={form.detail}
                  onChange={(e) => updateForm({ detail: e.target.value })}
                />
              </Col>
            </Form.Group>
            
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="amount" column sm="2">Amount</Form.Label>
              <Col sm="4">
                <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  name="amount"
                  id="amount"
                  aria-describedby="Amount"
                  placeholder="Amount"
                  value={form.amount}
                  onChange={(e) => updateForm({ amount: e.target.valueAsNumber })}
                />
                </InputGroup>
              </Col>
            </Form.Group>

            <FloatingLabel controlId="floatingTextarea2" label="Note">
            <Form.Control
              as="textarea"
              placeholder="Leave a note here"
              style={{ height: '100px' }}
              name="note"
              id="note"
              aria-describedby="Note"
              value={form.note}
              onChange={(e) => updateForm({ note: e.target.value })}
            />
          </FloatingLabel>
          </Form>
          </div>
        </div>
        <br/>
        <Button type="submit" variant="success">Save</Button>{' '}
        <NavStyle to="/home">Cancel</NavStyle>
      </form>
      }
    </>
  );
}