import React, { useEffect, useState } from 'react';
import { Table, Button, Container, Navbar, ButtonGroup, ToggleButton, Card, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styled from "styled-components";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Divider from '@mui/material/Divider';
import { format } from 'date-fns';
import { ImBin2 } from "react-icons/im";
import * as XLSX from 'xlsx';
import '../App.css';

const NavStyle = styled(NavLink)`
  color: green;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  padding: 4px;
  font-size: 12px;
  font-weight: 400;
  margin: 5px;
  outline: invert;
  &:link {
    transition : 0.5s;
    text-decoration: none;
  }
  &:hover {
    color: green;
  }
  &.active {
    color: aqua;
    position: relative;
    top: 2px;
  }
`

const NavStyle2 = styled(NavLink)`
  color: green;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  margin: 5px;
  outline: invert;
  &:link {
    transition : 0.5s;
    text-decoration: none;
  }
  &:hover {
    color: green;
  }
  &.active {
    color: aqua;
    position: relative;
    top: 2px;
  }
`

const exportToExcel = (data, fileName) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Records");

  // 버퍼로 파일 생성
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return format(date, 'yyyy-MM-dd'); // 예: January 01, 2024
};

const formatNumber = (num) => {
  if (num < 0) {
    num = num*(-1);
    return '-$'+num.toLocaleString(undefined, {minimumFractionDigits: 2});
  }
  return '$'+num.toLocaleString(undefined, {minimumFractionDigits: 2});
};

const Record = (props) => (
  <tr>
    <td>{formatDate(props.record.date)}</td>
    <td>{props.record.category}</td>
    <td>{props.record.detail}</td>
    <td>{props.record.name}</td>
    <td>{props.record.department}</td>
    <td>{props.record.consignee}</td>
    <td style={{ color: props.record.amount < 0 ? 'red' : 'black' }}>{formatNumber(props.record.amount)}</td>
    <td>{props.record.note}</td>
    <td>
      <NavStyle to={`/edit/${props.record._id}`}>Edit</NavStyle>
      &nbsp;&nbsp;
      <ImBin2 size="20" color='red' onClick={() => props.deleteRecord(props.record._id)} />
    </td>
  </tr>
);

export default function RecordList() {
  const [records, setRecords] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [incomeTotal, setIncomeTotal] = useState(0);
  const [expenseTotal, setExpenseTotal] = useState(0);
  const [radioValue, setRadioValue] = useState('total');
  const [startDate, setStartDate] = useState(new Date("01/01/2024")); //MM-DD-YYYY로 입력
  const [endDate, setEndDate] = useState(new Date("12/31/2024")); //MM-DD-YYYY로 입력


  const radios = [
    { name: 'Total', value: 'total' },
    { name: 'Income', value: 'income' },
    { name: 'Expense', value: 'expense' },
  ];

  // This method fetches the records from the database.
  useEffect(() => {
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth() + 1; // JavaScript months are 0-indexed
    const startDay = startDate.getDate();

    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth() + 1;
    const endDay = endDate.getDate();

    const fetchUrl = `http://202.169.212.28:5050/record/?startYear=${startYear}&startMonth=${startMonth}&startDay=${startDay}&endYear=${endYear}&endMonth=${endMonth}&endDay=${endDay}&radio=${radioValue}`;

    async function getRecords() {
      const response = await fetch(fetchUrl);
      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        console.error(message);
        return;
      }
      const records = await response.json();
      setRecords(records);
    }
      getRecords();
  }, [startDate, endDate, radioValue]);

  useEffect(() => {
    const sum = records.reduce((acc, record) => acc + (record.amount || 0), 0);
    const incomeSum = records.reduce((acc, record) => record.amount > 0 ? acc + record.amount : acc, 0);
    const expenseSum = records.reduce((acc, record) => record.amount < 0 ? acc + record.amount : acc, 0);
    setTotalAmount(sum);
    setIncomeTotal(incomeSum);
    setExpenseTotal(expenseSum);
  }, [records]);

  // This method will delete a record
  async function deleteRecord(id) {
    // Confirm before deleting
    if (window.confirm("Do you really want to DELETE it?")) {
      const response = await fetch(`http://202.169.212.28:5050/record/${id}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        console.error(`Failed to delete record with id ${id}: ${response.statusText}`);
        return;
      }
      const newRecords = records.filter((el) => el._id !== id);
      setRecords(newRecords);
    }
  }

  // This method will map out the records on the table
  function recordList() {
      return records.map((record) => {
        return (
          <Record
            record={record}
            deleteRecord={() => deleteRecord(record._id)}
            key={record._id}
          /> 
        );
      });
    
  }

  // This following section will display the table with the records of individuals.
  return (
    <>
    <Container>
      <Row className="justify-content-md-center">

          {/*Total Current Amount */}
        <Col>
          <Card
            bg="success"
            key="success"
            text='white'
            style={{ width: '18rem', margin: 'auto' }}
            className="mb-2"
          >
            <Card.Header>Total</Card.Header>
            <Card.Body>
              <Card.Title>{formatNumber(totalAmount)}</Card.Title>
            </Card.Body>
          </Card>
        </Col>

         {/*Total Income Amount of Listed Transactions */}
        <Col>
          <Card
            bg="light"
            key="light"
            text='dark'
            style={{ width: '18rem', margin: 'auto'}}
            className="mb-2"
          >
            <Card.Header>Income</Card.Header>
            <Card.Body>
              <Card.Title>{formatNumber(incomeTotal)}</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        {/*Total Expense Amount of Listed Transactions */}
        <Col>
          <Card
            bg="light"
            key="light"
            text='dark'
            style={{ width: '18rem', margin: 'auto' }}
            className="mb-2"
          >
            <Card.Header>Expense</Card.Header>
            <Card.Body>
              <Card.Title>{formatNumber(expenseTotal)}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <br />
    <Divider variant='middle' />
    <br />
    <Navbar>
      <Container>
        <Navbar.Collapse className="justify-content-center">
            <ButtonGroup>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={idx % 2 ? 'outline-success' : 'outline-success'}
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
    Start Date:
    <div>&nbsp;&nbsp;</div>
    <DatePicker
      showIcon
      className='calendar-input'
      dateFormat="dd/MM/yyyy"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      selectsStart
        startDate={startDate}
        endDate={endDate}
    />
    <div>&nbsp;&nbsp;</div>
    End Date:
    <div>&nbsp;&nbsp;</div>
    <DatePicker
      showIcon
      className='calendar-input'
      dateFormat="dd/MM/yyyy"
      selected={endDate}
      onChange={(date) => setEndDate(date)}
      selectsEnd
      startDate={startDate}
      endDate={endDate}
      minDate={startDate}
    />

        </Navbar.Collapse>
        <Button variant="success" onClick={() => exportToExcel(records, 'Records')}>Export to Excel</Button>
      </Container>
    </Navbar>
      
      <div class='App'>
      <br/>
        <div className='table-wrapper'>
          <Table style={ {fontSize: '14px'} } striped bordered hover>
            <thead>
            <tr>
              <th>Date</th>
              <th>Category</th>
              <th>Detail</th>
              <th>Name</th>
              <th>Department</th>
              <th>Consignee</th>
              <th>Amount</th>
              <th>Note</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
              {recordList()}
          </tbody>
          </Table>
        </div>
        <br />
        <NavStyle2 to="/create">Add Transaction</NavStyle2>
      </div>
    </>
  );
}