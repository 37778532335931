import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Container, Row, Col } from "react-bootstrap";


export default function RadioIO({ radioValue, setRadioValue }) {
    const radios = [
        { name: 'Income', value: 'income' },
        { name: 'Expense', value: 'expense' },
    ];

    return (
    <Container>
        <Row className="justify-content-md-center">
          <Col sm="4">
            <ButtonGroup>
              {radios.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  variant={idx % 2 ? 'outline-success' : 'outline-success'}
                  name="radio"
                  value={radio.value}
                  checked={radioValue === radio.value}
                  onChange={(e) => setRadioValue(e.currentTarget.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Col>
        </Row>
    </Container>
    );
}