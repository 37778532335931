import { Outlet } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import Divider from '@mui/material/Divider';
import React  from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from './assets/OverflowingLogo2.png';

const App = () => {
    return (
      <>
        <Container>
          <Row>
            <Col>
                <div class="App">
                    <img src={logo} alt='Logo'/>
                </div>
            </Col>
          </Row>
          <br />
          <Divider variant="middle" /> 
          <br />
          <Row>
            <Col>
            <div class='App'>
              <Outlet />
              <br />
              </div>
            </Col>
          </Row>
        </Container>
          <Row>
            <Col>
            <br />
            <Divider variant="middle" />
            <div class='App'>
              Ⓒ 2024 Overflowing Church of Auckland NZ. All Copyrights reserved.
            </div>
            </Col>
          </Row>
          <Container>
        </Container>
        </>
      );
};
export default App
